import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../../../services/axiosInstanceService';
import { adPlotsApiToStoreParser } from '../../adParser';

interface IFetchAdPlotLocationsThunkProps {
  isLand: boolean;
  area: number;
  address: string;
  tolerance: string;
  inseeCode: string;
}
const fetchAdPlotLocationsThunk = createAsyncThunk(
  'ad/fetchAdPlotLocationsThunk',
  async (params: IFetchAdPlotLocationsThunkProps) => {
    try {
      const { isLand, area, address, tolerance, inseeCode } = params;
      console.log('coucou');
      if (!area) throw new Error('No area found');
      if (!tolerance) throw new Error('No tolerance found');
      if (!inseeCode) throw new Error('No inseecode found');

      const response = await fetchDatas(`/parcels/${inseeCode}/result`, {
        is_land: isLand,
        area,
        address,
        tolerance,
      });

      const parsed = adPlotsApiToStoreParser(response['hydra:member']);

      return parsed;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchAdPlotLocationsThunk;
