import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { matchSorter } from 'match-sorter';
import { KeyboardEvent, useState } from 'react';
import useCities from '../../App/hooks/useCities';

interface IAutocompleteInputCitiesProps {
  multiple: boolean;
  value: Cities;
  name: string;
  handleChange: any;
  error?: any;
  helperText?: string;
  required?: boolean;
  isRa?: boolean;
}
const AutocompleteInputCities = ({
  multiple,
  value,
  name = 'city',
  handleChange,
  error,
  helperText,
  required,
  isRa,
}: IAutocompleteInputCitiesProps) => {
  const { cities, searchPattern, pattern, setPattern } = useCities();
  const [addWithPostalCode, setAddWithPostalCode] = useState<boolean>(false);
  const filterOptions = (
    options: Cities,
    { inputValue }: { inputValue: string }
  ) => {
    return matchSorter(options, inputValue, {
      keys: [(item) => item.name.replace(/\s|-/g, ' '), (item) => item.postalCode],
    });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (isRa) {
      const regex = /^[0-9]{5}$/;
      if (e.key === 'Enter') {
        //if enter and is a postalCode, add cities
        if (regex.test(pattern ?? '') && regex && addWithPostalCode) {
          const nc = [...value];
          cities?.forEach((elt) => {
            nc.push(elt);
          });

          //update value locally
          handleChange(name, nc);

          //reset states
          setAddWithPostalCode(false);
          setPattern(null);
        }
      } else {
        const val = pattern ? pattern + e.key : e.key;
        console.log(pattern);
        if (regex.test(pattern + e.key)) {
          //if length === 5 we can activate add action if enter
          setAddWithPostalCode(true);
        }

        if (val.length !== 5 && addWithPostalCode) {
          // if no good length, reset activate add action
          setAddWithPostalCode(false);
        }
      }
    }
  };

  const handleChangeInternal = (name: string, value: City | City[] | null) => {
    handleChange(name, value);
  };

  return (
    <>
      {addWithPostalCode && (
        <p className="postal-code-add-message">
          Tapez sur entrée pour saisir toutes les communes correspondant à ce code
          postal
        </p>
      )}
      <Autocomplete
        filterOptions={filterOptions}
        multiple={multiple}
        filterSelectedOptions
        options={cities ?? []}
        getOptionLabel={(option) => {
          return option.nameAndDepNumber ?? '';
        }}
        getOptionSelected={(option, value) => {
          return option.idIri === value.idIri;
        }}
        value={value ?? []}
        onChange={(evt, value) => handleChangeInternal(name, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            onChange={(e) => searchPattern(e.target.value)}
            variant="outlined"
            label="Ville"
            placeholder="Ville"
            name={name}
            InputLabelProps={{
              shrink: true,
            }}
            error={error}
            helperText={helperText}
            onKeyDown={handleKeyDown}
          />
        )}
      />
    </>
  );
};

export default AutocompleteInputCities;
