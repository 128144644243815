import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CheckboxCell from './CheckboxCell';

const useStyles = makeStyles({
  cell: {
    padding: 0,
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#000',
    },
  },
  img: {
    padding: 0,
    textAlign: 'center',
    verticalAlign: 'middle',
    '& img': {
      width: '40px',
    },
  },
});

const TableRowCustom = ({ data, folders, needCheck }) => {
  const classes = useStyles();

  const studiedContentDisplay = (data) => {
    if (!data.isStudied) {
      return ' - ';
    } else {
      const folderIriTab = folders.map((f) => f['@id']);
      let c = 0;
      let sharedFolder = undefined;

      while (c < data.studied.length && !sharedFolder) {
        sharedFolder = data.studied.find((e) => folderIriTab.includes(e));
      }

      return sharedFolder.idIri;
    }
  };

  const handleRowClick = (content) => {
    return (
      <Link to={`/ad/${data.idIri.split('/')[2]}`} target="_blank">
        {content}
      </Link>
    );
  };

  const handleDeadUrl = (e) => {
    e.target.src = '/images/logo-prospecimmo.svg';
  };

  return (
    <TableRow className={classes.row}>
      {needCheck && <CheckboxCell data={data} />}
      <TableCell className={classes.img}>
        {handleRowClick(
          <img
            src={data.picture ?? '/images/logo-prospecimmo.svg'}
            onError={handleDeadUrl}
            alt="good"
          />
        )}
      </TableCell>
      <TableCell className={classes.cell}>
        {handleRowClick(data.propertyTypology.name)}
      </TableCell>
      <TableCell className={classes.cell}>
        {handleRowClick(data.builtArea)}
      </TableCell>
      <TableCell className={classes.cell}>{handleRowClick(data.landArea)}</TableCell>
      <TableCell className={classes.cell}>
        {handleRowClick(data.city.name)}
      </TableCell>
      <TableCell className={classes.cell}>
        {data.source ? handleRowClick(data.source.label) : 'Indéfini'}
      </TableCell>
      <TableCell className={classes.cell}>
        {handleRowClick(`${data.price} €`)}
      </TableCell>
      <TableCell className={classes.cell}>{handleRowClick(data.status)}</TableCell>
      <TableCell className={classes.cell}>
        {studiedContentDisplay(data.studied)}
      </TableCell>
    </TableRow>
  );
};

TableRowCustom.propTypes = {
  data: PropTypes.object,
  folders: PropTypes.array,
  needCheck: PropTypes.bool,
};
export default TableRowCustom;
